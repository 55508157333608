<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t, } = useI18n()
const { locale } = useI18n()
const {
  customRouterState,
  navigateToPage
} = useCustomRouter()

const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)

onMounted(() => {
  if (authState.value.language === 'zh-hans') {
    locale.value = authState.value.language
  }
})
</script>

<template>
  <!-- <div
    class="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-[calc(100%-2rem)] max-w-lg bg-blue-800/95 flex justify-around items-center z-30 rounded-3xl text-xs h-20">
    <div class="text-center text-white w-1/6"
      :class="customRouterState == 'friend' && 'bg-blue-950 m-1 p-2 rounded-2xl'" @click="navigateToPage('friend')">
      <img src="/images/friends.png" alt="Friends" class="w-10 h-10 mx-auto" />
      <p class="mt-1 text-xs">Friends</p>
    </div>
    <div class="text-center text-white w-1/6"
      :class="customRouterState == 'flipcardgame' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('flipcardgame')">
      <img src="public/images/pennyworld/arcade.png" alt="GuessBTC" class="w-10 h-10 mx-auto" />
      <p class="mt-1 text-xs">Arcade</p>
    </div>
    <div class="text-center text-white w-1/6"
      :class="customRouterState == 'pennyworld' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('pennyworld')">
      <img src="public/images/pennyworld/World.png" alt="GuessBTC" class="w-10 h-10 mx-auto" />
      <p class="mt-1 text-xs">World</p>
    </div>
    
    <div class=" relative text-center text-white w-1/6" :class="customRouterState == 'mine' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="">
      <img src="public/images/pennyworld/stone.png" alt="Mine" class="w-9 h-10 mx-auto" />
      <p class="mt-1 text-xs">Store</p>
    </div>
    <div class="text-center text-white w-1/6"
      :class="customRouterState == 'airdrop' && 'bg-blue-950 m-1 p-2 rounded-2xl'" @click="navigateToPage('airdrop')">
      <img src="public/images/pennyworld/wallet.png" alt="Airdrop" class="w-10 h-10 mx-auto" />
      <p class="mt-1 text-xs">Wallet</p>
    </div>
  </div> -->

  <div
    class="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-[calc(100%-2rem)] max-w-lg bg-blue-800/95 flex justify-evenly items-center z-30 rounded-3xl text-xs h-20">
    <div class="relative text-center text-white w-1/6"
      :class="customRouterState == 'treasure' && 'bg-blue-950 m-1 p-2 rounded-2xl'" @click="navigateToPage('treasure')">
      <img src="/images/treasure.png" alt="Treasure" class="w-10 h-10 mx-auto" />
      <p class="mt-1 text-xs">{{t('Treasure')}} </p>
      <span v-if="authState.accumulatedEnergy >= authState.maximumEnergy"
        class="absolute top-1 right-1 grid min-h-[12px] min-w-[12px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 text-xs text-white">
      </span>
    </div>
    <div class=" relative text-center text-white w-1/6" :class="customRouterState == 'mine' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('mine')">
      <img src="public/images/pennyworld/mining.png" alt="Mine" class="w-9 h-10 mx-auto" />
      <p class="mt-1 text-xs">{{t('Upgrade')}}</p>
      <span v-if="authState.accumulatedPointsMining == authState.maxMiningPoints"
        class="absolute top-1 right-1 grid min-h-[12px] min-w-[12px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 text-xs text-white">
      </span>
    </div>
    <!-- <div class="text-center text-white w-1/6"
      :class="customRouterState == 'pennyworld' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('pennyworld')">
      <img src="public/images/pennyworld/World.png" alt="GuessBTC" class="w-10 h-10 mx-auto" />
      <p class="mt-1 text-xs">World</p>
    </div> -->
    <!-- <div class="text-center text-white w-1/6"
      :class="customRouterState == 'friend' && 'bg-blue-950 m-1 p-2 rounded-2xl'" @click="navigateToPage('friend')">
      <img src="/images/friends.png" alt="Friends" class="w-10 h-10 mx-auto" />
      <p class="mt-1 text-xs">Friends</p>
    </div> -->
    <!-- <div class="text-center text-white w-1/6"
      :class="customRouterState == 'flipcardgame' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('flipcardgame')">
      <img src="public/images/pennyworld/arcade.png" alt="GuessBTC" class="w-10 h-10 mx-auto" />
      <p class="mt-1 text-xs">Arcade</p>
    </div> -->
    
    <div class="relative text-center text-white w-1/6" :class="customRouterState == 'earn' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('earn')">
      <img src="/images/earn.png" alt="Earn" class="w-9 h-10 mx-auto" />
      <p class="mt-1 text-xs">{{t('Task')}}</p>
      <span v-if="authState.taskStatusOverall == 'not completed'"
        class="absolute top-1 right-1 grid min-h-[12px] min-w-[12px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 text-xs text-white">
      </span>
    </div>
    <!-- <div class="text-center text-white w-1/6"
      :class="customRouterState == 'airdrop' && 'bg-blue-950 m-1 p-2 rounded-2xl'" @click="navigateToPage('airdrop')">
      <img src="public/images/pennyworld/wallet.png" alt="Airdrop" class="w-10 h-10 mx-auto" />
      <p class="mt-1 text-xs">Wallet</p>
    </div> -->
  </div>
</template>